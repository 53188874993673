<template>
  <div class="appoint-stat">
    <!-- 为 ECharts 准备一个具备大小（宽高）的 DOM -->
    <!-- 用户预约渠道 -->
    <lineChart :id="'userAppointment'" :option="userAppointment"></lineChart>
    <!-- 人员跟进情况 -->
    <lineChart :id="'storeFollow'" :option="storeFollow"></lineChart>
    <!-- 跟进人员录入量 -->
    <lineChart :id="'personFollow'" :option="personFollow"></lineChart>
    <!-- 当月跟进状态 -->
    <lineChart :id="'personStatus'" :option="personStatus"></lineChart>
    <!-- 客户年龄 -->
    <lineChart :id="'userAge'" :option="userAge"></lineChart>
    <!-- 客户健康状况 -->
    <lineChart :id="'userHealth'" :option="userHealth"></lineChart>
    <!-- 全局加载 -->
    <van-overlay :show="isShow">
      <van-loading type="spinner" color="#CC9756" />
    </van-overlay>
  </div>
</template>
<script>
import { appointStatistical } from "@/api/api";
import lineChart from "../../../components/Echarts/lineChart";
// 1、导入echarts
import echarts from "echarts";
export default {
  data() {
    return {
      // 全局加载
      isShow: true,
      // 用户预约渠道
      userAppointment: {
        title: [
          {
            text: "门店统计",
            left: 10,
            top: 20,
          },
          {
            subtext: "用户预约渠道",
            left: "50%",
            top: "40",
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          bottom: 0,
          data: ["微信公众号", "官网", "媒体", "朋友", "同事", "其他"],
        },
        series: [
          {
            name: "用户预约渠道",
            type: "pie",
            radius: ["50%", "70%"],
            top: 60,
            bottom: 20,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            //设置饼状图每个颜色块的颜色
            color: [
              "#5b9bd5",
              "#f3983c",
              "#a5a5a5",
              "#ffc000",
              "#4472c4",
              "#70ad47",
            ],
            data: [
              { value: 0, name: "微信公众号" },
              { value: 0, name: "官网" },
              { value: 0, name: "媒体" },
              { value: 0, name: "朋友" },
              { value: 0, name: "同事" },
              { value: 0, name: "其他" },
            ],
          },
        ],
      },
      // 人员跟进情况
      storeFollow: {
        title: [
          {
            subtext: "人员跟进情况",
            left: "50%",
            top: "40",
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        legend: {
          bottom: 0,
          left: "center",
          data: ["已联系", "未联系"],
        },
        series: [
          {
            name: "人员跟进情况",
            type: "pie",
            radius: ["50%", "70%"],
            top: 60,
            bottom: 20,
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: "16",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            //设置饼状图每个颜色块的颜色
            color: ["#5b9bd5", "#f3983c"],
            data: [
              { value: 0, name: "未联系" },
              { value: 0, name: "已联系" },
            ],
          },
        ],
      },
      // 跟进人员录入量
      personFollow: {
        title: [
          {
            text: "跟进人员统计",
            left: 10,
            top: 20,
          },
          {
            subtext: "跟进人员录入量",
            left: "50%",
            top: "50",
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "axis",
        },
        color: ["#5b9bd5"],
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: "100",
          left: "10",
          right: "10",
          containLabel: true,
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 30,
          },
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: 30,
          },
        ],
        series: [
          {
            name: "录入量",
            data: [],
            type: "bar",
            backgroundStyle: {
              color: "#fff",
            },
          },
        ],
      },
      // 当月跟进状态
      personStatus: {
        title: [
          {
            subtext: "当月跟进状态",
            left: "50%",
            top: 20,
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          bottom: 0,
          left: "center",
          data: [
            "跟进中",
            "已预约已参观",
            "已预约未参观",
            "已签单",
            "已超时",
            "注销",
          ],
        },
        color: [
          "#5b9bd5",
          "#ed7d31",
          "#a5a5a5",
          "#ffc000",
          "#4472c4",
          "#70ad47",
        ],
        series: [
          {
            name: "当月跟进状态",
            type: "pie",
            radius: "50%",
            bottom: 40,
            center: ["50%", "60%"],
            data: [
              { value: 0, name: "跟进中" },
              { value: 0, name: "已预约已参观" },
              { value: 0, name: "已预约未参观" },
              { value: 0, name: "已签单" },
              { value: 0, name: "已超时" },
              { value: 0, name: "注销" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      // 客户年龄统计
      userAge: {
        title: [
          {
            text: "客户统计",
            left: 10,
            top: 20,
          },
          {
            subtext: "客户年龄状况",
            left: "50%",
            top: "40",
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        color: [
          "#5b9bd5",
          "#ed7d31",
          "#a5a5a5",
          "#ffc000",
          "#4472c4",
          "#70ad47",
          "#255e91",
          "#de7e7b",
        ],
        legend: {
          bottom: 0,
          data: [
            "65~70岁",
            "71~75岁",
            "76~80岁",
            "81~85岁",
            "86~90岁",
            "91~95岁",
            "96~100岁",
            "100岁以上",
          ],
        },
        series: [
          {
            name: "客户年龄状况",
            type: "pie",
            radius: "50%",
            center: ["50%", "60%"],
            bottom: 40,
            data: [
              { value: 0, name: "65~70岁" },
              { value: 0, name: "71~75岁" },
              { value: 0, name: "76~80岁" },
              { value: 0, name: "81~85岁" },
              { value: 0, name: "86~90岁" },
              { value: 0, name: "91~95岁" },
              { value: 0, name: "96~100岁" },
              { value: 0, name: "100岁以上" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      // 客户健康状况统计
      userHealth: {
        title: [
          {
            subtext: "客户健康状况",
            left: "50%",
            top: 40,
            textAlign: "center",
          },
        ],
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          bottom: 10,
          data: ["自理长者", "半失能长者", "全失能长者"],
        },
        color: ["#5b9bd5", "#ed7d31", "#a5a5a5"],
        series: [
          {
            name: "客户健康状况",
            type: "pie",
            radius: "50%",
            center: ["50%", "60%"],
            bottom: 30,
            data: [
              { value: 0, name: "自理长者" },
              { value: 0, name: "半失能长者" },
              { value: 0, name: "全失能长者" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },
  components: {
    lineChart,
  },
  created() {
    this.getAppointStatistical();
  },
  methods: {
    // 获取预约数据
    getAppointStatistical() {
      appointStatistical().then((res) => {
        if (res.data.length === 0) {
          this.isShow = false;
        }
        // 用户预约渠道
        const arrplaces = Object.values(res.data.arrplaces);
        this.userAppointment.series[0].data.map((item, index) => {
          item.value = arrplaces[index];
          this.userAppointment.series[0].data[0].value = res.data.arrplaces.wx;
          this.userAppointment.series[0].data[1].value = res.data.arrplaces.gw;
          this.userAppointment.series[0].data[2].value = res.data.arrplaces.mt;
          this.userAppointment.series[0].data[3].value = res.data.arrplaces.py;
          this.userAppointment.series[0].data[4].value = res.data.arrplaces.ts;
          this.userAppointment.series[0].data[5].value = res.data.arrplaces.qt;
        });

        // 人员跟进情况
        const contacts = Object.values(res.data.contacts);
        this.storeFollow.series[0].data.map((item, index) => {
          item.value = contacts[index];
        });

        // 跟进人员录入量
        let Xdata = [];
        let Ydata = [];
        res.data.users.map((item) => {
          Xdata.push(item.name);
          Ydata.push(item.count);
        });
        this.personFollow.xAxis.data = Xdata;
        this.personFollow.series[0].data = Ydata;

        // 当月跟进状态
        const arrorderstates = Object.values(res.data.arrorderstates);
        this.personStatus.series[0].data.map((item, index) => {
          item.value = arrorderstates[index];
          this.personStatus.series[0].data[0].value =
            res.data.arrorderstates.bedoing;
          this.personStatus.series[0].data[1].value =
            res.data.arrorderstates.subscribe;
          this.personStatus.series[0].data[2].value =
            res.data.arrorderstates.novisit;
          this.personStatus.series[0].data[3].value =
            res.data.arrorderstates.finish;
          this.personStatus.series[0].data[4].value =
            res.data.arrorderstates.timeout;
          this.personStatus.series[0].data[5].value =
            res.data.arrorderstates.logout;
        });

        // 客户年龄统计
        const arrages = Object.values(res.data.arrages);
        this.userAge.series[0].data.map((item, index) => {
          item.value = arrages[index];
        });

        // 客户健康状况统计
        const arrhealths = Object.values(res.data.arrhealths);
        this.userHealth.series[0].data.map((item, index) => {
          item.value = arrhealths[index];
        });
        this.isShow = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.appoint-stat {
  width: 100%;
  height: 100%;
  background-color: #fff;
  margin-top: 26px;
  padding-top: 5px;
}
.van-overlay {
  z-index: 999;
}
</style>