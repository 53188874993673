<template>
  <div
    :id="id"
    :option="option"
    class="mychart"
    :style="{ height: height }"
  ></div>
</template>
<script>
import echarts from "echarts";
export default {
  name: "linechart",
  props: {
    id: {
      type: String,
      default: () => "",
    },
    option: {
      type: Object,
      default: () => {},
    },
    height: {
      type: String,
      default: () => "10rem",
    },
  },
  data() {
    return {
      myChart: null,
    };
  },
  mounted() {
    // 页面加载完毕调用渲染图表的方法
    this.lineChart(this.id, this.option);
    // 处理echarts响应式问题
    window.addEventListener("resize", function () {
      if (this.myChart) {
        this.myChart.resize();
      }
    });
  },
  methods: {
    // 渲染图表
    lineChart(id, option) {
      if (this.myChart) this.myChart.clear();
      if (document.getElementById(id)) {
        this.myChart = echarts.init(document.getElementById(id));
        this.myChart.setOption(option, true);
      }
    },
  },
  watch: {
    // 监听父组件传过来option的变化（主要是数据切换）
    option: {
      handler(newValue) {
        if (document.getElementById(this.id)) {
          this.myChart = echarts.init(document.getElementById(this.id));
          this.lineChart(this.id, newValue);
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.mychart {
  max-width: 100%;

  /deep/div:nth-child(1) {
    width: 100% !important;
    max-width: 100%;
  }

  /deep/canvas {
    // width: 100% !important;
    max-width: 100%;
  }
}
</style>